/* eslint-disable */
import jwtDecode from "jwt-decode";
import { API_ERROR } from "../constants/constants";
import { client, getJWToken } from "./client";
import { appConfig } from "../../config/app-config";
import {
  ApiResponse,
  ConfigurationsRequest,
  DEHistoryRequest,
  UpdateCustomerPayload,
} from "../interface/globalInterface";

//Base Urls
const CUSTOMER_BASE_URL: string = appConfig.apiUrls.micro;
const MENU_BASE_URL: string = appConfig.apiUrls.menu;
const STOREMGMT_BASE_URL: string = appConfig.apiUrls.storemgmt;
const PAYMENT_BASE_URL: string = appConfig.apiUrls.payment;
const CASHMGMT_BASE_URL: string = appConfig.apiUrls.cashmgmt;
const AGREEMENT_BASE_URL: string = appConfig.apiUrls.agreement;

/** Note: All the function names are declared here are same as Frontend Lambda's name */

const GetStoreProfile = async (
  payload: ConfigurationsRequest
): Promise<ApiResponse> => {
  try {
    return await client("POST", STOREMGMT_BASE_URL, `store-details`, payload);
  } catch (error: any) {
    console.log("Error in GetStoreProfile: ", error?.message);
    return API_ERROR;
  }
};

const GetMenuAndStoreBasedOnProfile = async (payload: any): Promise<ApiResponse> => {
  try {
    return await client("POST", MENU_BASE_URL, "menu-and-stores", payload);
  } catch (error: any) {
    console.log("Error in GetMenuAndStoreBasedOnProfile", error?.message);
    return API_ERROR;
  }
};

const GetLegalStatus = async (customerId: string): Promise<ApiResponse> => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/legal-hold`
    );
  } catch (error: any) {
    console.log("Error in GetLegalStatus: ", error?.message);
    return API_ERROR;
  }
};

const GetCustomer = async (
  customerId: string,
  storeNumber: string
): Promise<ApiResponse> => {
  try {
    return await client("PUT", CUSTOMER_BASE_URL, `customer/${customerId}`, {
      storeNumber: storeNumber,
    });
  } catch (error: any) {
    console.log("Error in GetCustomer: ", error?.message);
    return API_ERROR;
  }
};

const UpdateCustomerInfo = async (
  payload: UpdateCustomerPayload,
  customerId: string
): Promise<ApiResponse> => {
  try {
    return await client(
      "PUT",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/information`,
      payload
    );
  } catch (error: any) {
    console.log("Error in UpdateCustomerInfo: ", error?.message);
    return API_ERROR;
  }
};

const GetCustomerInfoOptions = async (
  storeNumber: string
): Promise<ApiResponse> => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/customerinfo-options/${storeNumber}`
    );
  } catch (error: any) {
    console.log("Error in GetCustomerInfoOptions: ", error?.message);
    return API_ERROR;
  }
};

const GetEmployementOptions = async (
  sequenceId: string
): Promise<ApiResponse> => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/employement-options/${sequenceId}`
    );
  } catch (error: any) {
    console.log("Error in GetEmployementOptions: ", error?.message);
    return API_ERROR;
  }
};

const GetPaymentHistory = async (
  customerId: string,
  type: string,
  offset: any
) => {
  try {
    return await client(
      "GET",
      PAYMENT_BASE_URL,
      `payment1/details/history/${customerId}?type=${type}&limit=10&offset=${offset}`
    );
  } catch (error: any) {
    console.log("Error in GetPaymentHistory: ", error?.message);
    return API_ERROR;
  }
};

export const GetAgreements = async (
  customerId: string,
  inactive: "Y" | "N"
): Promise<ApiResponse> => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/agreements?inActive=${inactive}`
    );
  } catch (error: any) {
    console.log("Error in GetAgreements: ", error?.message);
    return API_ERROR;
  }
};

const ManageDrawerAudit = async (
  payload: DEHistoryRequest
): Promise<ApiResponse> => {
  try {
    let url = `cashmgmt/dehistory/${payload.globalCustomerId}`;
    if (payload.limit && payload.offset) {
      url += `?limit=${payload.limit}&offset=${payload.offset}`;
    }
    return await client("GET", CASHMGMT_BASE_URL, url);
  } catch (error: any) {
    console.log("Error in ManageDrawerAudit: ", error?.message);
    return API_ERROR;
  }
};

const DrawerAudit = async (payload: DEHistoryRequest): Promise<ApiResponse> => {
  try {
    return await client(
      "POST",
      CASHMGMT_BASE_URL,
      `manage/draweraudit`,
      payload
    );
  } catch (error: any) {
    console.log("Error in DrawerAudit: ", error?.message);
    return API_ERROR;
  }
};

const AddEventId = async (payload: any): Promise<ApiResponse> => {
  try {
    return await client(
      "POST",
      AGREEMENT_BASE_URL,
      `agreement/address/eventId`,
      payload
    );
  } catch (error: any) {
    console.log("Error in AddEventId: ", error?.message);
    return API_ERROR;
  }
};

export const StoreSearch = async (
  storeNumber: string
): Promise<ApiResponse> => {
  try {
    return await client("POST", CUSTOMER_BASE_URL, `customer/store-search`, {
      storeNumbers: storeNumber,
    });
  } catch (error: any) {
    console.log("Error in StoreSearch: ", error?.message);
    return API_ERROR;
  }
};

const GetCustomerAlertsById = async (
  customerId: string
): Promise<ApiResponse> => {
  const queryValue = Math.floor(Math.random() * 1000000 + 1);
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/alerts/${customerId}?alert=${queryValue}`
    );
  } catch (error: any) {
    console.log("Error in GetCustomerAlertsById: ", error?.message);
    return API_ERROR;
  }
};

const GetCustomerAlerts = async (customerId: string): Promise<ApiResponse> => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/alerts/assign`
    );
  } catch (error: any) {
    console.log("Error in GetCustomerAlerts: ", error?.message);
    return API_ERROR;
  }
};

const GetCustomerCoverage = async (
  customerId: string,
  storeNumber: string
): Promise<ApiResponse> => {
  try {
    return await client(
      "PUT",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/${storeNumber}`
    );
  } catch (error: any) {
    console.log("Error in GetCustomerCoverage: ", error?.message);
    return API_ERROR;
  }
};

const ValidateAddress = async (payload: any): Promise<ApiResponse> => {
  try {
    return await client(
      "POST",
      CUSTOMER_BASE_URL,
      `customer/address/validate`,
      payload
    );
  } catch (error: any) {
    console.log("Error in ValidateAddress: ", error?.message);
    return API_ERROR;
  }
};

export const GetCustomerAddress = async (customerId: any, active: any) => {
  try {
    return await client(
      "GET",
      CUSTOMER_BASE_URL,
      `customer/address/${customerId}?active=${active}`
    );
  } catch (error: any) {
    console.log("Error in GetCustomerAddress: ", error?.message);
    return API_ERROR;
  }
};

const UpdateAlert = async (payload: any, customerId: any) => {
  try {
    return await client(
      "PUT",
      CUSTOMER_BASE_URL,
      `customer/${customerId}/update-customer-alert`,
      payload
    );
  } catch (error: any) {
    console.log("Error in GetCustomerAddress: ", error?.message);
    return API_ERROR;
  }
}

export const UpdateAddressInfo = async (payload: any) => {
  try {
    return await client(
      'POST',
      CUSTOMER_BASE_URL,
      `/customer/address/update`,
      payload
    );
  } catch (error: any) {
    console.log("Error in UpdateAddressInfo: ", error?.message);
    return API_ERROR;
  }
};

export const GetCustomerPhone = async (customerId: any, active: any) => {
  try {
    return await client(
      'GET',
      CUSTOMER_BASE_URL,
      `customer/phone/${customerId}?active=${active}`,
      
    );
  } catch (error: any) {
   console.log("Error in GetCustomerPhone: ", error?.message);
    return API_ERROR;
  }
};

const decryptData = async (payload: any) => {
  try {
    return await client(
    `POST`,
    appConfig.apiUrls.cashmgmt,
    `manage/draweraudit`,
    payload
  );
} catch (e: any){
  console.log("Error in Decryption API: ", e);
  return API_ERROR;
}
};

const getCurrentUser = async (): Promise<any> => {
  return await client(
    'GET',
    appConfig.apiUrls.container,
    'users/current'
  );
}

export const GetActivateClub = async (payload: any) => {
  
  try {
    return await client(
      `POST`,
      CUSTOMER_BASE_URL,
      `customer/activate-club`,
      payload
    );
  } catch (e: any) {
    return null;
  }
};

export {
  GetStoreProfile,
  GetMenuAndStoreBasedOnProfile,
  GetLegalStatus,
  GetCustomer,
  GetEmployementOptions,
  GetCustomerInfoOptions,
  GetPaymentHistory,
  UpdateCustomerInfo,
  ManageDrawerAudit,
  AddEventId,
  GetCustomerAlertsById,
  GetCustomerAlerts,
  UpdateAlert,
  GetCustomerCoverage,
  ValidateAddress,
  DrawerAudit,
  decryptData,
  getCurrentUser
};
