/* eslint-disable */
import { ADDRESS_INFO_TEMPLATE } from '../../../constants/constants';
import {
  AddressDetails,
  AddressDetailsBinder,
  AddressInfo,
  AddressInformationProps,
} from '../../../interface/globalInterface';

/*
    Main Function to build the Addresses based on the type and returns 
    the primary and delivery addresses.
*/
export const buildAdressInformations = (
  addressInfo: AddressInformationProps
): AddressInfo => {
  const primaryAddressArray: AddressDetails[] = filterAndProcessAddresses(addressInfo, 'PRIM');
  const deliveryAddressArray: AddressDetails[] = filterAndProcessAddresses(addressInfo, 'DELIV');
  const mailingAddressesArray: AddressDetails[] = filterAndProcessAddresses(addressInfo, 'MAIL');
  return {
    primaryAddress: primaryAddressArray,
    deliveryAddress: deliveryAddressArray,
    mailingAddresses: mailingAddressesArray
  };
};

/*
    Filter the addresses based on the type
    Gets the params as the Array of AddressDetails and the address type like PRIM, DELIV, MAIL
*/
const filterAddressesByType = (addressInfo: AddressDetails[], addressType: string): AddressDetails[] => {
  return addressInfo.filter((element: AddressDetails) => element.addressType == addressType);
}

/*
    Based on the input request it will form the object which is AddressDetails object and 
    returns to the service.
*/
const getAddressDetailsObject = (filteredAddressById: AddressDetails[], addressIds: string[], agreementNumbers: string[], storeNumbers: string[], agreementIds: string[]) => {
  return {
    addressId: filteredAddressById[0].addressId,
    addressIds: addressIds,
    agreementId: filteredAddressById[0].agreementId,
    agreementIds: agreementIds,
    agreementNumber: filteredAddressById[0].agreementNumber,
    agreementNumbers: agreementNumbers,
    storeNumbers: storeNumbers,
    agreementNumbersCount: agreementNumbers.length ? String(agreementNumbers.length) : '-',
    storeNumber: filteredAddressById[0].storeNumber,
    addressType: filteredAddressById[0].addressType,
    addressTypeDesc: filteredAddressById[0].addressTypeDesc,
    addressLine1: filteredAddressById[0].addressLine1,
    addressLine2: filteredAddressById[0].addressLine2,
    city: filteredAddressById[0].city,
    state: filteredAddressById[0].state,
    lastModifiedBy: filteredAddressById[0].lastModifiedBy,
    lastModifiedDate: filteredAddressById[0].lastModifiedDate,
    postalCode: filteredAddressById[0].postalCode
  }
}

/*
    This function is form the addresses based on the condition
*/
const processAddress = (
  element: AddressDetails,
  addresses: AddressDetails[],
  addressResponse: AddressDetails[]
) => {
  const addressIds: string[] = [];
  const agreementNumbers: string[] = [];
  const storeNumbers: string[] = [];
  const agreementIds: string[] = [];
  const filteredAddressById: AddressDetails[] = addresses.filter(
    (ele: AddressDetails) =>
      ele.addressId == element.addressId
  );
  if (
    filteredAddressById &&
    filteredAddressById.length &&
    !addressIds.includes(element.addressId + '-' + element.storeNumber)
  ) {
    addressIds.push(element.addressId + '-' + element.storeNumber);
    filteredAddressById.forEach((element: AddressDetails) => {
      if (element.agreementNumber && element.agreementId) {
        agreementNumbers.push(element.agreementNumber);
        agreementIds.push(element.agreementId);
      }
      if (element.storeNumber && !storeNumbers.includes(element.storeNumber)) {
        storeNumbers.push(element.storeNumber);
      }
    });

    const existingAddress = addressResponse.find(
      (addr) => addr.addressId === element.addressId
    );

    if (!existingAddress) {
      addressResponse.push(
        getAddressDetailsObject(
          filteredAddressById,
          addressIds,
          agreementNumbers,
          storeNumbers,
          agreementIds
        )
      );
    }
  }
};

/*
    This function is filters the addresses based on the type and triggers
    the function.
*/
const filterAndProcessAddresses = (addressInfo: AddressInformationProps, filterType: string): AddressDetails[] => {
  const responseAddress: AddressDetails[] = [];
  const addresses: AddressDetails[] = filterAddressesByType(addressInfo.activeAddresses, filterType);
  if (addresses && addresses.length) {
    addresses.forEach((element: AddressDetails) => {
      processAddress(element, addresses, responseAddress);
    })
  };
  return responseAddress;
}

/*
    Trim the string based on the max value 
    based on that max value less than 3 it will trims the string
    and return
*/
export const trimContent = (content: string, max: number) => {
  if (content.length > max) {
    return `${content.slice(0, max - 3)}...`;
  } else {
    return content;
  }
};

/*
    Create a new array of objects to bind into the Dynamic binder
*/
export const buildAddressDetailsBinder = (
  addressInfo: AddressInfo
): AddressDetailsBinder[] => {
  const responseArray: AddressDetailsBinder[] = [];
  let isMailAddSameAsPrimAdd = false;

  // Checking whether primary address and mailing address are same
  if (addressInfo.primaryAddress && addressInfo.mailingAddresses) {
    isMailAddSameAsPrimAdd = mailAddSameAsPrimAdd(addressInfo.primaryAddress[0], addressInfo.mailingAddresses[0])
  }

  // Pushing primaryAddress into responseArray
  addressInfo.primaryAddress && responseArray.push(formAddressDetailsBinderObject(addressInfo.primaryAddress[0], 'Primary Address', true, false, isMailAddSameAsPrimAdd, 'PRIM', false));

   // Pushing deliveryAddress into responseArray
  addressInfo.deliveryAddress && addressInfo.deliveryAddress.map((element: AddressDetails, index: number) => {
    responseArray.push(formAddressDetailsBinderObject(element, index == 0 ? 'Delivery Address' : '', false, true, isMailAddSameAsPrimAdd, 'DELIV', false))
  });

   // Pushing mailingAddresses into responseArray
  if (addressInfo.mailingAddresses && addressInfo.mailingAddresses.length > 0) {
    addressInfo.mailingAddresses && addressInfo.mailingAddresses.map((element: AddressDetails, index: number) => {
      responseArray.push(formAddressDetailsBinderObject(element, index == 0 ? 'Mailing Address' : '', false, false, isMailAddSameAsPrimAdd, 'MAIL', false))
    });
  } else {
    const element = {...ADDRESS_INFO_TEMPLATE};
    responseArray.push(formAddressDetailsBinderObject(element, 'Mailing Address', false, false, isMailAddSameAsPrimAdd, 'MAIL', true))
  }

  return responseArray;
};

const mailAddSameAsPrimAdd = (primaryAddress: any, mailAddress: any): boolean => {
  let isSame = false;
  if (
    (primaryAddress && mailAddress &&
      primaryAddress.addressLine1 == mailAddress.addressLine1 &&
      primaryAddress.addressLine2 == mailAddress.addressLine2 &&
      primaryAddress.city == mailAddress.city &&
      primaryAddress.postalCode == mailAddress.postalCode &&
      primaryAddress.state == mailAddress.state) ||
    (!mailAddress || !mailAddress.addressLine1)
  ) {
    isSame = true;
  }
  return isSame;
}

const formAddressDetailsBinderObject = (addressInfo: AddressDetails, heading: string, toggleEnable: boolean, isDelivery: boolean,
  isMailAddSameAsPrimAdd: boolean, addressType: string, isEmptyMailAddress: boolean
): AddressDetailsBinder => {
  return {
    addressId: addressInfo?.addressId,
    showAddress: (isMailAddSameAsPrimAdd && heading == 'Mailing Address') ? false : true,
    isEdited: false,
    isEditEnabled: false,
    heading: heading,
    toggleEnable: toggleEnable,
    isDelivery: isDelivery,
    storeNumber: addressInfo?.storeNumber ? addressInfo.storeNumber : null,
    agreementNumber: addressInfo?.agreementNumbers ? addressInfo.agreementNumbers : null,
    agreementIds: addressInfo?.agreementIds || [],
    addressLine1: addressInfo?.addressLine1 ? addressInfo.addressLine1 : null,
    addressLine2: addressInfo?.addressLine2 ? addressInfo.addressLine2 : null,
    zipCode: addressInfo?.postalCode ? addressInfo.postalCode : null,
    city: addressInfo?.city ? addressInfo.city : null,
    state: addressInfo?.state ? addressInfo.state : null,
    isMailAddSameAsPrimAdd: isMailAddSameAsPrimAdd,
    addressType: addressType,
    isEmptyMailAddress: isEmptyMailAddress
  }
}

export const buildInActiveAddressDetails = (addressDetails: AddressDetails[]): AddressDetails[] => {

  const addresses = addressDetails || [];

  const addressID: string[] = [];
  const inActiveAddressArray: AddressDetails[] = [];

  addresses.forEach((address: AddressDetails) => {
    const filteredAddress = addresses.filter((val: AddressDetails) =>
      val.addressId === address.addressId && val.storeNumber === address.storeNumber
    );

    if (filteredAddress.length > 0 && !addressID.includes(`${address.addressId},${address.storeNumber}`)) {
      addressID.push(`${address.addressId},${address.storeNumber}`);

      const multipleAgreementNumber = filteredAddress.map(item => item.agreementNumber).join(", ");

      // eslint-disable-next-line no-console
      console.log(filteredAddress, multipleAgreementNumber, 'Multiple Agreements and filter Addresses');

      inActiveAddressArray.push({
        addressId: address.addressId,
        agreementId: address.agreementId,
        agreementNumber: multipleAgreementNumber,
        agreementNumbersCount: String(filteredAddress.length >= 1 && !multipleAgreementNumber ? '-' : filteredAddress.length),
        storeNumber: address.storeNumber,
        addressType: address.addressType,
        addressTypeDesc: address.addressTypeDesc,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        lastModifiedBy: address.lastModifiedBy,
        lastModifiedDate: address.lastModifiedDate,
      });
    }
  });

  return inActiveAddressArray;
}

/**
 * This function formates the address from timestamp to US format date
 * For Eg: 2024-07-16T12:29:49.990593-05:00 to 07/18/2024
 */
export const formatAddress = (date: string) => {
  const splitedDate = date.split('T')[0];
  const actualDate = splitedDate.split('-');
  return `${actualDate[1]}/${actualDate[2]}/${actualDate[0]}`
}
