/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { Grid, RACButton, RACToggle, Typography } from "@rentacenter/racstrap";
import { globalStyles } from './../../jsStyles/globalStyles';
import { updateArrayOfObjectState, updateObjectStateVariable } from './Utils';
import { DynamicVerification } from '../../interface/globalInterface';
import { UpdateCustomerContext } from '../../context/UpdateCustomerContext';

export const VerificationComponent = (props: { value: DynamicVerification }) => {
  const { isButtonEnabled, editEnabled, validateInputFields, actualVerifiedValue, updatedVerifiedValue, setterFunction, isArrayOfObject, index, stateVariable } = props.value;
  const { configurations } = useContext(UpdateCustomerContext);
  const IntellicheckURL = configurations.intellicheck;
  const classes = globalStyles();
  // eslint-disable-next-line no-console
  console.log('Inside the verificationComponent', props.value);
  return (
    <Grid container className={classes.width100p}>
      <Grid container md={12} className={`${classes.mt20px}`}>
        <Grid item md={12}>
          <Typography
            className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
          >
            Verification
          </Typography>
          <Typography
            className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.lightGreyColor} ${classes.mt5px}`}
          >
            Please enter all required fields to enable Verification
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={12} className={`${classes.mt20px}`}>
        {isButtonEnabled ? (
          <Grid item md={2} lg={2} xs={6}>
            <RACButton
              variant="outlined"
              color="primary"
              disabled={false}
              onClick={() => window.open(IntellicheckURL)}
            >
              Launch Intellicheck
            </RACButton>
          </Grid>
        ) : null}
        <Grid item md={3} lg={3} xs={6}>
          <RACToggle
            rightText={true}
            rightLabel={`Verified`}
            labelClassName={`${classes.ml10px}`}
            toggleColor="#0d6efd"
            GridClassName={`${classes.displayFlex} ${classes.mt10px}`}
            disabled={!editEnabled || !validateInputFields}
            checked={
              !editEnabled
                ? actualVerifiedValue.verified
                : updatedVerifiedValue.verified
            }
            OnChange={(e) => {
              if (isArrayOfObject && stateVariable) {
                updateArrayOfObjectState(
                  stateVariable,
                  setterFunction,
                  index,
                  { verified: e.target.checked }
                )
              } else {
                updateObjectStateVariable(
                  setterFunction,
                  "verified",
                  e.target.checked
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};