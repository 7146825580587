/* eslint-disable */

import { Grid, Typography } from "@rentacenter/racstrap";
import React, { useContext } from "react";
import { VerificationStatus } from "../../interface/globalInterface";
import { ReactComponent as CompletedIcon } from "./../../assets/images/Completed-icon.svg";
import { globalStyles } from "../../jsStyles/globalStyles";
import moment from "moment";
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";

export const DynamicVerifiedStatus = (props: { value: VerificationStatus }) => {
  const { coworkerIds } = useContext(UpdateCustomerContext);
  const classes = globalStyles();
  const { verifiedBy, verifiedSource, verifiedOn } = props.value;
  console.log(
    "Verified Status",
    verifiedBy,
    verifiedSource,
    verifiedOn,
    coworkerIds,
    coworkerIds[verifiedBy]
  );
  return (
    <Grid item style={{ display: "flex", alignItems: "self-end" }}>
      <CompletedIcon
        className={classes.successicon}
        style={{ marginTop: "14px" }}
      />{" "}
      <Typography
        display="inline"
        className={`${classes.fontSize16px} ${classes.fontOpensansSemiBold} ${classes.ml10px}`}
      >
        {`Verified in `}
        {verifiedSource
          ? verifiedSource.toLowerCase() === "racpad"
            ? "RACPad"
            : verifiedSource.toLowerCase() === "ec" ||
              verifiedSource.toLowerCase() === "ecom"
            ? "ECOM"
            : verifiedSource
          : "RACPad"}
        {` by ${
          verifiedSource.toLowerCase() === "ec" ||
          verifiedSource.toLowerCase() === "ecom"
            ? "ECOM"
            : verifiedBy
            ? coworkerIds[verifiedBy]
              ? coworkerIds[verifiedBy]
              : "Unknown"
            : "Unknown"
        } on ${
          verifiedOn
            ? moment(verifiedOn).format("MM/DD/YYYY")
            : moment().format("MM/DD/YYYY")
        }`}
      </Typography>
    </Grid>
  );
};
