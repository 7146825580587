/* eslint-disable */
/* eslint-disable prettier/prettier */

import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  DropdownOptions,
  DynamicVerification,
  EmployerDetails,
  IdentityDetailsInfo,
  IdentityDetailsProps,
  ParamType,
  ReferenceDetail,
  ResidenceInfo,
} from "../../../interface/globalInterface";
import { globalStyles } from "../../../jsStyles/globalStyles";
import {
  isNotNullVariable,
  updateCustomerAndVerification,
  updateObjectStateVariable,
  updateObjectWithMultipleKeys,
} from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { VerificationComponent } from "./../VerifiedComponent";
import { useParams } from "react-router-dom";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";
import moment from "moment";
import { GOVT_ID_TYPE_CVO, MODULE_NAME } from "../../../constants/constants";
import { getMaskedValue } from "../../../../utils/utils";
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function IdentityDetails(props: {
  value: IdentityDetailsProps;
}) {
  const {
    govtIDTypeOptions,
    stateOptions,
    screenLoader,
    setScreenLoader,
    setGetCustomerInfoResponse,
    setGetApprovalRes,
    showDecryptedValues,
    currentRole,
    coworkerIds,
    currentEmpId,
    editEnabled, 
    setEditEnabled
  } = useContext(UpdateCustomerContext);
  const {
    identityDetails,
    setIdentityDetails,
    errorMessage,
    actualIdentityDetails,
    setActualIdentityDetials,
    setIdentityErrorMessage,
  } = props.value;
  const { customerId } = useParams<ParamType>();
  const [secondLevelAuth, setSecondLevelAuth] = useState<boolean>(false);
  const mandatoryFieldsCheck: boolean =
    (identityDetails.governmentId !== "" ||
      identityDetails.governmentIdType !== "" &&
      identityDetails.governmentIdType === "NONE" ||
      identityDetails.issuedState !== "");
  const validateInputFields: boolean = (
    identityDetails.governmentId !== '' &&
    identityDetails.governmentIdType !== '' &&
    identityDetails.issuedState !== ''
  )
  // const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const classes = globalStyles();
  const [isGovtIdClicked, setGovIdClicked] = useState<boolean>(false);
  const verificationTabPayload: DynamicVerification = {
    isButtonEnabled: true,
    validateInputFields,
    editEnabled,
    actualVerifiedValue: actualIdentityDetails,
    updatedVerifiedValue: identityDetails,
    setterFunction: setIdentityDetails as React.Dispatch<
      React.SetStateAction<
        IdentityDetailsInfo | ResidenceInfo | ReferenceDetail | EmployerDetails
      >
    >,
  };

  const handleEditButton = async (isAuthenticated: boolean) => {
    // eslint-disable-next-line no-console

    if (isAuthenticated || !identityDetails.verified) {
      setScreenLoader(true);
      setSecondLevelAuth(false);
      let updatePayload: any = identityDetails;
      updatePayload.modifiedBy = currentEmpId;
      const updateDetailsResponse: boolean =
        await updateCustomerAndVerification(
          updatePayload,
          "ID",
          customerId ? customerId : "",
          setGetCustomerInfoResponse,
          setGetApprovalRes,
        );
      if (updateDetailsResponse) {
        setEditEnabled(false);
        const updatedDetails: IdentityDetailsInfo = {
          ...identityDetails,
          verified: identityDetails.verified,
          verifiedBy: currentEmpId,
          verifiedSource: 'RACPad',
          verifiedDate: moment().format('MM/DD/YYYY')
        };
        setActualIdentityDetials(updatedDetails);
      } else {
        setEditEnabled(false);
        setIdentityDetails(identityDetails);
      }
      setScreenLoader(false);
    } else {
      setSecondLevelAuth(true);
    }
  };

  const validateFields = () => {
    const errorMessage: IdentityDetailsInfo = {
      governmentId: "",
      governmentIdType: "",
      issuedState: "",
      verified: false,
      verifiedDate: "",
      overrideEnabled: false,
      decisionEngineId: "",
      verifiedSource: "",
      govtIdDecrpted: "",
      verifyCode: "",
      verifiedBy: "",
    };

    if (!isNotNullVariable(identityDetails.governmentIdType)) errorMessage.governmentIdType = 'Required'
    else errorMessage.governmentIdType = '';
    if (!isNotNullVariable(identityDetails.governmentId)) errorMessage.governmentId = 'Required'
    else errorMessage.governmentId = '';

    setIdentityErrorMessage(errorMessage);
    return (!errorMessage.governmentId && !errorMessage.governmentIdType)
  }


  // const updatedGovtIdTypes = govtIDTypeOptions?.filter((element: DropdownOptions) => GOVT_ID_TYPE_CVO.includes(element.value));
  const handleBlur = () => {
    setGovIdClicked(true);
  };

  const handleFocus = () => {
    setGovIdClicked(false);
  };

  const removeErrorMessages = () => {
    const errorMessage : any = {
      governmentId: '',
      governmentIdType: '',
      issuedState: '',
      verified: false,
      verifiedDate: '',
      overrideEnabled: false,
      decisionEngineId: '',
      verifiedSource: ''
    };
    setIdentityErrorMessage(errorMessage);
  }

  return (
    <Grid container className={`${classes.mt10px}`}>
      <Grid container md={12} className={`${classes.mt10px}`}>
        <Grid item md={2}>
          <Typography
            className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
          >
            Identity Document Information
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={12} spacing={2} className={`${classes.mt10px}`}>
        <Grid item md={2} lg={2} xs={4}>
          <RACSelect
            required={true}
            options={govtIDTypeOptions}
            loading={screenLoader}
            inputLabel="Gov ID Type"
            onChange={(e: any) =>
              updateObjectWithMultipleKeys(setIdentityDetails, {
                governmentIdType: e.target.value,
                ...(e.target.value == "NONE"
                  ? {
                    governmentId: "",
                    govtIdDecrpted: "",
                    issuedState: "",
                  }
                  : null),
              })
            }
            defaultValue={
              !editEnabled
                ? actualIdentityDetails.governmentIdType
                : identityDetails.governmentIdType
            }
            errorMessage={errorMessage.governmentIdType}
            isDisabled={!editEnabled}
          />
        </Grid>
        <Grid item md={2} lg={2} xs={4}>
          <RACTextbox
            type="text"
            required={true}
            inputlabel="Gov ID"
            maxlength={30}
            onFocus={handleFocus}
            Onblur={handleBlur}
            OnChange={(e: any) => {
              updateObjectStateVariable(
                setIdentityDetails,
                "governmentId",
                e.target.value.replace(/[^a-zA-Z0-9*]/g, '')
              );
            }}
            value={
              !editEnabled
                ? showDecryptedValues ? actualIdentityDetails.govtIdDecrpted : isGovtIdClicked ? getMaskedValue(actualIdentityDetails.governmentId) : actualIdentityDetails.governmentId
                : showDecryptedValues ? identityDetails.govtIdDecrpted : isGovtIdClicked ? getMaskedValue(identityDetails.governmentId) : identityDetails.governmentId
            }
            errorMessage={errorMessage.governmentId}
            disabled={
              !editEnabled || identityDetails.governmentIdType == "NONE" || showDecryptedValues
            }
          />
        </Grid>
        <Grid item md={2} lg={2} xs={4}>
          <RACSelect
            required={true}
            options={stateOptions}
            loading={screenLoader}
            inputLabel="State"
            onChange={(e: any) =>
              updateObjectWithMultipleKeys(setIdentityDetails, {
                issuedState: e.target.value,
              })
            }
            defaultValue={
              !editEnabled
                ? actualIdentityDetails.issuedState
                : identityDetails.issuedState
            }
            errorMessage={errorMessage.issuedState}
            isDisabled={
              !editEnabled || identityDetails.governmentIdType == "NONE"
            }
          />
        </Grid>
      </Grid>
      {(!actualIdentityDetails.verified || editEnabled) && actualIdentityDetails.decisionEngineId ? <VerificationComponent value={verificationTabPayload} /> : null}
      {/* <OverrideComponent value={verificationTabPayload}/> */}
      <Grid
        container
        className={`${classes.mt10px} ${classes.displayFlex} ${classes.flexEnd} ${classes.pr2p}`}
        md={12}
      >
        <Grid item md={10}>
          {actualIdentityDetails.verified ? (
            <DynamicVerifiedStatus
              value={{
                verifiedBy: actualIdentityDetails.verifiedBy,
                verifiedSource: actualIdentityDetails.verifiedSource,
                verifiedOn: actualIdentityDetails.verifiedDate,
              }}
            />
          ) : null}
        </Grid>
        <Grid item md={2} className={`${classes.alignRight}`}>
          {editEnabled ? (
            <RACButton
              variant="outlined"
              color="primary"
              onClick={() => {
                setEditEnabled(false);
                setIdentityDetails(actualIdentityDetails);
                removeErrorMessages();
              }}
            >
              Cancel
            </RACButton>
          ) : null}
          <RACButton
            variant="contained"
            color="primary"
            className={`${classes.ml10px} ${classes.alignRight}`}
            // disabled={editEnabled ? !mandatoryFieldsCheck : false}
            onClick={async () => {
              editEnabled ? validateFields() ? handleEditButton(false) : null : (setEditEnabled(true), updateObjectStateVariable(setIdentityDetails,
                "verified",
                false));
            }}
          >
            {`${editEnabled ? "Save" : "Edit"}`}
          </RACButton>
        </Grid>
      </Grid>
      {secondLevelAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setSecondLevelAuth(false);
            setEditEnabled(false);
            setIdentityDetails(actualIdentityDetails);
          }}
          setTwoFactorCompleted={(e: any) => handleEditButton(e)}
          moduleName={MODULE_NAME.IDENTITY_VERIFICATION}
          currentRole={currentRole}
        />
      ) : null}
    </Grid>
  );
}
