/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  RACButton,
  RACTextbox,
  RACModalCard,
} from '@rentacenter/racstrap';
import Modal from 'react-modal';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Erroricon from '../../assets/images/erroricon.png';
import PinField from 'react-pin-field';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  GetRemoteLoginStatus,
} from '../../api/user';
export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
};
import { customerStyles } from '../customerStyles';
import { SecondFactorStyles } from '../SecondFactorStyles';
import clsx from 'clsx';
export default function SecondFactor(props: PropsInterface) {
  const classes = SecondFactorStyles();
  const classesValidate = customerStyles();
  const { setTwoFactorCancelClick, setTwoFactorCompleted, setEmployeeDetails } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const [openRemotePopup, setopenRemotePopup] = useState(false);
  const [remoteId, setremoteId] = useState('');
  const [securityCode, setsecurityCode] = useState('');
  const [errorMessage, seterrorMessage] = useState('');

  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  const rolesExpansion: any = {
    AM: 'Assistant Manager',
    SM: 'Store Manager',
    DMT: 'DM in Training',
    DM: 'District Manager',
    RDT: 'RD in Training',
    RD: 'Regional Director',
    LAM: 'Lead Assistant Manager',
    SSM: 'Senior Store Manager',
  };
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  const [loader, setloader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };
  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };
  const handleRemoteID = (event: any) => {
    setremoteId(event.target.value);
  };

  const handleSecurityCode = (event: any) => {
    setsecurityCode(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    console.log('cur role payload', payload);
    let result = await getsecondfactor(payload);
    console.log('result for coworkerlogin', result);
    setloader(false);
    UserValidate(result);
  };
  const remoteContinue = async (remoteId: any, securityCode: any) => {
    setopenRemotePopup(false);
    setloader(true);
    const payload = {
      userName: remoteId,
      passCode: securityCode,
      requiredRole: role,
    };
    seterrorMessage('');
    let result = await GetRemoteLoginStatus(payload);
    console.log('GetRemoteLoginStatus', result);
    setloader(false);
    RemoteValidate(result);
  };

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };
  const RemoteValidate = (result: any) => {
    console.log('RemoteValidate', result);
    if (result?.status == 200) {
      if (result?.response?.result == 'allow') {
        setopenRemotePopup(false);
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      } else {
        seterrorMessage(result?.response?.status_msg);
        setopenRemotePopup(true);
      }
    } else {
      console.log('Hi from remote validate', result);
      seterrorMessage(result?.data?.errors[0]?.error?.split('"')[7]);
      setopenRemotePopup(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };
  useEffect(() => {
    setsomethingone('modal fade show expandPopup');
    setsomethingtwo('modal fade');
    const payload = {
      module: window.localStorage.getItem('moduleName') || props.moduleName,
      storeNumber: sessionStorage.getItem('storeNumber'),
      currentRole:
        window.localStorage.getItem('currentRole') || props.currentRole,
    };
    const PageLoad = async () => {
      let result = await getprofileaccess(payload);
      result = result?.data;
      setloader(false);
      console.log('Role', result);
      setRole(result?.role);
      popupSelect(result);
    };
    PageLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const popupSelect = (result: any) => {
    if (result.pin === 1 && result.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };
  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);
  const remoteModelCancelClick = () => {
    seterrorMessage('');
    setopenRemotePopup(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    let userResponse = await getCurrentInfo();
    userResponse = userResponse.data;
    const id = userResponse.employeeId
      ? userResponse.employeeId
      : userResponse.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse)
      : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    let result = await getPinDetails(payload);
    setInvalidPin(result.status);
    result = result?.data
    setloader(false);
    setOpenPinModal(true);
    if (result.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
    if (result.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
  };
  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };
  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const remoteEnter = (event: any, remoteId: any, securityCode: any) => {
    if (event.charCode === 13 && remoteId !== '' && securityCode !== '') {
      event.preventDefault();
      remoteContinue(remoteId, securityCode);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };
  const pinValue = (event: any) => {
    otp = event;
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onCloseClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };
  const onCloseSecondfacClick = () => {
    setOpenModal(false);
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onPinCloseClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const remoteClick = () => {
    setOpenModal(false);
    setopenRemotePopup(true);
  };

  const openModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid
            className={`${classes.floatLeft} ${classes.my2} ${classes.mx1}`}
          >
            <Typography
              className={`${classes.racLoginAlert} ${classes.RACPOPMsg}`}
            >
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              className={classes.w100}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event: any) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event: any) =>
                secondfactorEnter(event, userID, password)
              }
              isCurrency={false}
              className={classes.w100}
              type="password"
              value={password}
              OnChange={(event: any) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.me2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => remoteClick()}
          >
            Remote
          </RACButton>
          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.me2}
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={`${classes.racBtnDisabled} ${classes.me2}`}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const openRemoteModal = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorMessage !== '' ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racRemoteLoginAlert}>
              {errorMessage}
            </Typography>
          </Grid>
        ) : null}
        <Grid className={classes.spacerMargin}>
          <Typography className={classes.racRemoteLogin}>
            {`Please enter the ${rolesExpansion[role] ? rolesExpansion[role] + ' or' : 'your'
              } above level security code to
            continue`}
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Remote ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={remoteId}
              OnChange={(event) => handleRemoteID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Security Code
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => remoteEnter(event, remoteId, securityCode)}
              isCurrency={false}
              type="password"
              value={securityCode}
              OnChange={(event) => handleSecurityCode(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={remoteModelCancelClick}
          >
            Cancel
          </RACButton>
          {remoteId !== '' && securityCode !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => remoteContinue(remoteId, securityCode)}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo></RACLogo>
          <Typography
            className={`${classes.racErrorFunction} ${classes.RACPOPMsg}`}
          >
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openPinModalContent = () => {
    return (
      <div style={{ padding: '0px 35px' }}>
        <Grid container className={classes.racPinContainer}>
          <Grid item lg={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid onKeyPress={pinEnter}>
            <PinField
              id="pininput"
              className={classes.racPinField}
              length={4}
              validate="0123456789"
              style={{ marginLeft: '0.3rem' }}
              type="password"
              autoFocus={true}
              onComplete={(event: any) => pinValue(event)}
              onChange={(event: any) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography
                id="InvalidPin"
                className={`${classes.racPinError} ${classes.RACPOPMsg}`}
              >
                Invalid Pin{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.me2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.me2}
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <Modal
        isOpen={loader}
        className={classesValidate.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <Grid>
          <Grid>
            <Grid className={classes.RACLoaderPage}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <RACModalCard
        borderRadius="20px"
        isOpen={openModal}
        onClose={onCloseSecondfacClick}
        maxWidth={'xs'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openRemotePopup}
        borderRadius="20px"
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openRemoteModal()}
      ></RACModalCard>
      <RACModalCard
        borderRadius="20px"
        isOpen={openSecondModal}
        closeIcon={true}
        onClose={onCloseClick}
        maxWidth={'sm'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openAlertModalContent()}
      ></RACModalCard>
      <RACModalCard
        borderRadius="20px"
        isOpen={openPinModal}
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </div>
  );
}