/* eslint-disable */
import {
    Card,
    CardContent,
    Grid,
    RACCheckBox,
    RACSelect,
    Typography,
} from '@rentacenter/racstrap';
import { API_ERROR_MESSAGE, DEFAULT_DROPDOWN_OPTION, EMPTY_STRING } from '../../../constants/constants';
import { valueExistsInDropdown } from '../../../utils/utils';
import { updateCustomerStyles } from '../../../jsStyles/updateCustomerStyles';
import { DropdownOptions } from '../../../interface/globalInterface';

interface Legalprops {
  legalHoldStatus: boolean;
  skip: boolean;
  hard: boolean;
  legalHoldReason: string;
  legalHoldDropdown: DropdownOptions[];
  dropdownLoader: boolean;
}

export function Legal(props: Legalprops) {
  const classes = updateCustomerStyles();
  const {
    legalHoldStatus,
    skip,
    hard,
    legalHoldReason,
    legalHoldDropdown,
    dropdownLoader,
  } = props;

  return (
    <>
      <Grid key={"legalContainer"} id={"legalContainer"} container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.mb10}
          key={"legalTitleContainer"}
        >
          <Typography
            component="label"
            id="legalInformation"
            className={`${classes.title}`}
          >
            Legal
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          key={"legalContentContainer"}
          id={"legalContentContainer"}
        >
          <Card className={classes.cardStyle}>
            <CardContent>
              <Grid container>
                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"legalCheckBox"}
                    label="Legal Hold"
                    size="small"
                    name={"legalCustomer"}
                    checked={legalHoldStatus || false}
                    disabled={true}
                  />
                </Grid>

                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"skipCheckBox"}
                    label="Skip"
                    size="small"
                    name={"skipCustomer"}
                    checked={skip || false}
                    disabled={true}
                  />
                </Grid>

                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"hardCheckBox"}
                    label="Hard"
                    size="small"
                    name={"hardCustomer"}
                    checked={hard || false}
                    disabled={true}
                  />
                </Grid>

                {legalHoldStatus ? (
                  <Grid item md={2} style={{marginLeft:'30px', display:'flex', alignItems:'center'}}>
                    <RACSelect
                      key={"legalHoldReason"}
                      inputLabel="Legal Hold Reason"
                      loading={dropdownLoader || false}
                      options={legalHoldDropdown}
                      defaultValue={valueExistsInDropdown(
                        legalHoldDropdown,
                        legalHoldReason
                      )}
                      isDisabled={true}
                      errorMessage={
                        !dropdownLoader && legalHoldDropdown?.length === 1
                          ? API_ERROR_MESSAGE
                          : EMPTY_STRING
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
