/* eslint-disable */
import { Box, Grid, makeStyles, Typography } from "@rentacenter/racstrap";
import { ReactComponent as Calendar } from "../../../CustomerRefactor/assets/images/calendar.svg";
import React, { ChangeEvent, useEffect } from "react";
import DatePicker from "react-datepicker";
import { TODAY_DATE_TIME } from "../../constants/constants";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "2px",
    color: "#111111",
            fontFamily: "OpenSans-semibold",
            fontSize: "14px",
  },
  colorRed:{
    color:'red'
  },
  valuePlaceHolder:{
    width: "100%",
    float: "left",
    borderRadius: "6px",
    padding: "0.2875rem 0.7rem 0.2875rem 0.7rem",
    border: "1px solid #d9d9d9",
  },
  iconStyle:{
    float: "right", 
    width: "14px", 
    height: "19px"
  },
  dateField: {
            padding: 0,
            border: 'none',
            width: 0,
        },
}));


interface MaskableDatePickerProps {
  loading: boolean;
  isDisable: boolean;
  value: string;
  isRequired: boolean;
  errorMessage: string;
  unmask?: boolean;
  onChange: (date: string) => any;
}

export function MaskableDatePicker(props: MaskableDatePickerProps) {
  const { isDisable, unmask, value, isRequired, errorMessage, onChange } = props;
  const classes = useStyles();

  //Maximum year constaint
  const OneTenYearOld = new Date(
    TODAY_DATE_TIME.getFullYear() -
      110 +
      "-" +
      (TODAY_DATE_TIME.getMonth() + 1) +
      "-" +
      (TODAY_DATE_TIME.getDate() < 10
        ? "0" + TODAY_DATE_TIME.getDate()
        : TODAY_DATE_TIME.getDate())
  );

  //Minimum year constaint
  const EighteenYearsOld = new Date(
    TODAY_DATE_TIME.getFullYear() -
      18 +
      "-" +
      (TODAY_DATE_TIME.getMonth() + 1) +
      "-" +
      (TODAY_DATE_TIME.getDate() < 10
        ? "0" + TODAY_DATE_TIME.getDate()
        : TODAY_DATE_TIME.getDate())
  );

  //Passing the value alone to the actual place of use
  const handleOnChange=((event:any)=>{
    try{
      onChange(moment(event).format("YYYY/MM/DD").replaceAll("/", "-"));
    }catch(error:any){
      console.log('Error in datePickerOnChange: ', error?.message);
    }
  })

  //To sync the change of value with the actual place of use
  useEffect(() => {}, [value]);
  
  return (
    <>
      <Grid>
        <Grid
          item
          component="div"
          className={classes.title}
          key={'DateTileContainer'}
          id={'DateTileContainer'}
        >
          Date of Birth
          {isRequired ? (
            <Box
            key={'MandatoryDate'}
            id={'MandatoryDate'}
              component="span"
              className={classes.colorRed}            
            >
              *
            </Box>
          ) : null}
        </Grid>

        <Grid>
          <Typography
          key={'dateValuelabel'}
          id={'dateValuelabel'}
            className={classes.valuePlaceHolder}
            style={{ backgroundColor: isDisable ? '#e4e4de' : '' }}
            onClick={() =>
              (
                document.getElementById("DateOfBirth") as HTMLInputElement
              ).focus()
            }
          >
            {value
              ? unmask
                ? moment(value).format("MM/DD/YYYY")
                : moment(value).format("MM/DD/YYYY").substring(0, 6) + "****"
              : "MM/DD/YYYY"}
            <Calendar
              className={classes.iconStyle}
              key={'DateIcon'}
              id={'DateIcon'}
              onClick={() =>
                (
                  document.getElementById("DateOfBirth") as HTMLInputElement
                ).focus()
              }
            ></Calendar>
            <DatePicker
              className={classes.dateField}
              id={"DateOfBirth"}
              key={'DateOfBirth'}
              fixedHeight
              disabled={isDisable}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              yearDropdownItemNumber={100}
              showYearDropdown
              scrollableYearDropdown
              selected={value ? new Date(value) : EighteenYearsOld}
              minDate={OneTenYearOld}
              maxDate={EighteenYearsOld}
              shouldCloseOnSelect={true}
              onChange={(e: any) => {
               handleOnChange(e)
              }}
            />
          </Typography>
        </Grid>

        <Grid key={'DateError'} id={'DateError'}>
          {value && errorMessage !== "" ? (
            <label
            key={'DateMessage'}
            id="DateMessage"
              className={classes.colorRed}          
            >
              {errorMessage}
            </label>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
