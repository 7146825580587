/* eslint-disable */

import React, { useContext } from "react";
import {
  Grid,
  Link,
  RACButton,
  RACSelect,
  RACTextbox,
  RACToggle,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { useHistory, useParams } from "react-router-dom";
import {
  AddressDetailsBinder,
  AddressDetailsBinderObject,
} from "../../../interface/globalInterface";
import { updateArrayOfObjectState } from "../Utils";
import { addressValidation } from "./AddressValidation";
import { FeatureFlagContext } from "./../../../../context/FeatureFlagContext";
import { UpdateCustomerContext } from "./../../../context/UpdateCustomerContext";
import { AddressValidationPopUp } from "./AddressValidationPopup";

export default function DynamicDetailsBinder({
  value,
  setterData,
  addressValidationPayload
}: AddressDetailsBinderObject) {
  const classes = globalStyles();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const { featureFlagDetails } = useContext(FeatureFlagContext);

  const { setScreenLoader, setBtnIsdisabled1, setBtnIsdisabled2,
    setAddressErrorMsg, setSuggestedAddress, setDefaultAddress, setmatchCode,
    setValidateIsOpen, setValidatedAddress, setCanSuggestAddress,
    setAddressDoc, cantSuggestAddress, setCantSuggestAddress, setAddressIndex, stateOptions, 
    Agrtransferinit } = useContext(UpdateCustomerContext);

  const loginStore = sessionStorage.getItem('storeNumber');

  const renderAgreementNumbers = (agreementNumbers: string[]) => {
    if (agreementNumbers && agreementNumbers.length) {
      return (
        <>
          {agreementNumbers.map((ele: string, index: number) => (
            <Link
              key={index}
              underline="none"
              onClick={() =>
                history.push(`/agreement/info/details/${customerId}/${ele}`)
              }
              className={`${classes.fontOpensansBold}`}
            >
              {index == 0 ? ele : `, ${ele}`}
            </Link>
          ))}
        </>
      );
    }
    return `-`;
  };

  const renderDeliveryAddressHeader = (element: AddressDetailsBinder) => {
    return (
      <Grid
        container
        className={`${classes.width50p} ${classes.mt10px} ${classes.ml10px}`}
      >
        <Grid item className={`${classes.width50p}`}>
          <Typography
            className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
          >{`Store # : ${element.storeNumber ? element.storeNumber : "-"
            }`}</Typography>
        </Grid>
        <Grid item className={`${classes.width50p} ${classes.fontSize16px}`}>
          <Typography
            component={`span`}
            className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
          >{`Agreement # : `}</Typography>
          {element.agreementNumber && element.agreementNumber.length
            ? renderAgreementNumbers(element.agreementNumber)
            : "-"}
        </Grid>
      </Grid>
    );
  };

  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setDefaultAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setDefaultAddress('ENT');
    }
    else if (type == 'GA') {
      setDefaultAddress('GA')
    }
  };

  const handleToggleButton = (checked: boolean) => {
    const updatedAddresses = setterData.editedAddress.map((val: AddressDetailsBinder, index: number) => {
      if (index === 0) {
        return { ...val, isMailAddSameAsPrimAdd: checked };
      } else if (val.addressType === 'MAIL') {
        return { ...val, showAddress: !checked, isEditEnabled: true };
      }
      return val;
    });

    // Update the state with the new array
    setterData.setEditedAddress(updatedAddresses);
    setterData.setActiveAddress(updatedAddresses);

  };

  return (
    <>
      {value.addressDetails
        ? value.addressDetails.map(
          (element: AddressDetailsBinder, index: number) => (
            <>
              {element.showAddress ? (
                <Grid
                  container
                  className={`${classes.width100p}`}
                  key={index}
                >
                  <Grid
                    container
                    className={`${classes.width100p} ${classes.mt10px}`}
                  >
                    {element.heading ? (
                      <Grid item className={`${classes.width50p}`}>
                        <Typography
                          className={`${classes.mt10px} ${classes.fontSize16px}  ${classes.fontOpensansBold}`}
                        >
                          {element.heading}
                        </Typography>
                      </Grid>
                    ) : null}
                    {element.toggleEnable ? (
                      <Grid
                        item
                        className={`${classes.width50p} ${classes.displayFlex} ${classes.justifyContentEnd}`}
                      >
                        <RACToggle
                          checked={setterData.editedAddress[0].isMailAddSameAsPrimAdd}
                          toggleColor="#0d6efd"
                          OnChange={(e: any) => {
                            handleToggleButton(e.target.checked)
                          }}
                        />
                        <Typography
                          component="span"
                          className={`${classes.mt0px} ${classes.ml10px} ${classes.fontSize14px} ${classes.mt10px}`}
                        >
                          Mailing address same as primary?
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                  {element.isDelivery
                    ? renderDeliveryAddressHeader(element)
                    : null}
                  <Grid
                    container
                    className={`${classes.width100p} ${classes.mt10px} ${classes.ml5px}`}
                    spacing={2}
                  >
                    <Grid container md={12} spacing={2}>
                      <Grid item lg={3} md={2} xl={2}>
                        <RACTextbox
                          required={true}
                          type={"text"}
                          id="DeliveryAddressField"
                          inputlabel={`Address Line 1`}
                          name={``}
                          placeHolder={`Address Line 1`}
                          OnChange={(e: any) => {
                            updateArrayOfObjectState(
                              setterData.editedAddress,
                              setterData.setEditedAddress,
                              index,
                              { addressLine1: e.target.value, isEdited: true }
                            );
                          }}
                          maxlength={30}
                          value={
                            setterData.editedAddress[index]?.isEdited
                              ? setterData.editedAddress[index]
                                ?.addressLine1 || ""
                              : element?.addressLine1 || ""
                          }
                          disabled={
                            Agrtransferinit ||
                            !setterData.editedAddress[index]?.isEditEnabled
                          }
                          errorMessage={!setterData.editedAddress[index]?.addressLine1 ? 'Required' : ''}
                        />
                      </Grid>
                      <Grid item lg={3} md={2} xl={2}>
                        <RACTextbox
                          type={"text"}
                          id="DeliveryAddressField"
                          inputlabel={`Address Line 2`}
                          name={``}
                          placeHolder={`Address 2, APT #/Unit #, P.O.Box`}
                          infoIcon={true}
                          infoTitle={`Address 2, APT #/Unit #, P.O.Box`}
                          OnChange={(e: any) => {
                            updateArrayOfObjectState(
                              setterData.editedAddress,
                              setterData.setEditedAddress,
                              index,
                              { addressLine2: e.target.value, isEdited: true }
                            );
                          }}
                          maxlength={30}
                          value={
                            setterData.editedAddress[index]?.isEdited
                              ? setterData.editedAddress[index]
                                ?.addressLine2 || ""
                              : element?.addressLine2 || ""
                          }
                          disabled={
                            Agrtransferinit ||
                            !setterData.editedAddress[index]?.isEditEnabled
                          }
                        />
                      </Grid>
                      <Grid item lg={3} md={2} xl={2}>
                        <RACTextbox
                          required={true}
                          type={"text"}
                          inputlabel={`Zip`}
                          name={``}
                          placeHolder={`Zip Code`}
                          OnChange={(e: any) => {
                            let zipCode = ('' + e.target.value).replace(/\D/g, '');
                            zipCode =
                              zipCode.length > 5
                                ? zipCode.slice(0, 5) + '-' + zipCode.slice(5)
                                : zipCode;
                            updateArrayOfObjectState(
                              setterData.editedAddress,
                              setterData.setEditedAddress,
                              index,
                              {
                                zipCode: zipCode,
                                isEdited: true,
                              }
                            );
                          }}
                          maxlength={10}
                          value={
                            setterData.editedAddress[index]?.isEdited
                              ? setterData.editedAddress[index]?.zipCode || ""
                              : element?.zipCode || ""
                          }
                          disabled={
                            Agrtransferinit ||
                            !setterData.editedAddress[index]?.isEditEnabled
                          }
                          errorMessage={!setterData.editedAddress[index]?.zipCode ? 'Required' :
                            setterData.editedAddress[index]?.zipCode?.length != 5 &&
                              setterData.editedAddress[index]?.zipCode?.length != 10 ? 'Invalid Zip' : ''}
                        />
                      </Grid>
                      <Grid item lg={3} md={2} xl={2}>
                        <RACTextbox
                          required={true}
                          type={"text"}
                          id="DeliveryAddressField"
                          inputlabel={`City`}
                          name={``}
                          placeHolder={`City`}
                          OnChange={(e: any) => {
                            updateArrayOfObjectState(
                              setterData.editedAddress,
                              setterData.setEditedAddress,
                              index,
                              {
                                city: e.target.value,
                                isEdited: true,
                              }
                            );
                          }}
                          maxlength={30}
                          value={
                            setterData.editedAddress[index]?.isEdited
                              ? setterData.editedAddress[index]?.city || ""
                              : element?.city || ""
                          }
                          disabled={
                            Agrtransferinit ||
                            !setterData.editedAddress[index]?.isEditEnabled
                          }
                          errorMessage={!setterData.editedAddress[index]?.city ? 'Required' : ''}
                        />
                      </Grid>
                      <Grid item lg={3} md={2} xl={2}>
                        <RACSelect
                          required={true}
                          options={stateOptions}
                          inputLabel="State"
                          onChange={(e: any) => {
                            updateArrayOfObjectState(
                              setterData.editedAddress,
                              setterData.setEditedAddress,
                              index,
                              {
                                state: e.target.value,
                                isEdited: true,
                              }
                            );
                          }}
                          defaultValue={
                            setterData.editedAddress[index]?.isEdited
                              ? setterData.editedAddress[index]?.state || ""
                              : element.state || ""
                          }
                          isDisabled={
                            Agrtransferinit ||
                            !setterData.editedAddress[index]?.isEditEnabled
                          }
                          errorMessage={!setterData.editedAddress[index]?.state ? 'Required' : ''}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} xl={3}>
                        <RACButton
                          variant="contained"
                          color="primary"
                          className={`${classes.mt10px}`}
                          onClick={async () => {
                            if (
                              setterData.editedAddress[index]?.isEditEnabled
                            ) {
                              updateArrayOfObjectState(
                                setterData.editedAddress,
                                setterData.setEditedAddress,
                                index,
                                {
                                  isEditEnabled: true,
                                }
                              );
                              setAddressIndex(index);
                              const validateAddressPayload = {
                                updatedAddress: setterData.editedAddress[index],
                                setScreenLoader: setScreenLoader,
                                enableGoogleAddressValidation: (featureFlagDetails.EnableGoogleAddressValidation || '1'),
                                setDefaultAddress: setDefaultAddress,
                                setmatchCode: setmatchCode,
                                setAddressErrorMsg: setAddressErrorMsg,
                                setSuggestedAddress: setSuggestedAddress,
                                setValidateIsOpen: setValidateIsOpen,
                                setValidatedAddress: setValidatedAddress,
                                setCanSuggestAddress: setCanSuggestAddress,
                                setAddressDoc: setAddressDoc,
                                cantSuggestAddress: cantSuggestAddress,
                                setCantSuggestAddress: setCantSuggestAddress,
                                RadioBtnclick: RadioBtnclick
                              }
                              await addressValidation(validateAddressPayload)

                            } else {
                              updateArrayOfObjectState(
                                setterData.editedAddress,
                                setterData.setEditedAddress,
                                index,
                                {
                                  isEditEnabled: true,
                                  isEdited: true,
                                }
                              );
                            }
                          }}
                          disabled={element?.isDelivery && (element?.storeNumber != loginStore)}
                        >
                          {setterData.editedAddress[index]?.isEditEnabled
                            ? `Validate`
                            : `Edit Address`}
                        </RACButton>
                        {setterData.editedAddress[index]?.isEditEnabled ? (
                          <RACButton
                            variant="outlined"
                            color="primary"
                            className={`${classes.ml10px} ${classes.mt10px}`}
                            onClick={() => {
                              updateArrayOfObjectState(
                                setterData.editedAddress,
                                setterData.setEditedAddress,
                                index,
                                {
                                  ...element,
                                  isEditEnabled: false,
                                  isEdited: false,
                                }
                              );
                            }}
                          >
                            Cancel
                          </RACButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </>
          )
        )
        : null}

      <AddressValidationPopUp value={setterData} data={value} addressValidationPayload={addressValidationPayload}/>

    </>
  );
}